import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCheck } from "@fortawesome/free-solid-svg-icons";
import UserChat from "./UserChat";
import fillter from "../../../Images/fillter.svg";
import FillterFavorite from './FillterFavorite';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { connectSocket, emitRealTimeMessagesSocket, onRealTimeMessagesSocket } from "../../../socket/NotificationSocket";
// import io from 'socket.io-client';
import { socket, socketMessageContext } from "../../../context/SocketMessageContext";
import NoMore from '../../../Images/no-da.svg';
import Loaders from '../../Loaders/index'

function InboxMessage() {
  const { unreadAlerts, setUnreadAlerts } = useContext(socketMessageContext);
  const [loading, setLoading] = useState(true);

  const [selectedFilter, setSelectedFilter] = useState("All");

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  // console.log("unreadAlerts",unreadAlerts)

  const options = [
    { value: "All", label: "All" },
    { value: "Unread", label: "Unread" },
    { value: "Starred", label: "Starred" },
  ];


  // const socket = io(process.env.REACT_APP_WEB_SOCKET_URL);
  const [ib, setIb] = useState(null);
  const [ibFilter, setIbFilter] = useState("");
  const [ibSearch, setIbSearch] = useState("");


  useEffect(() => {
    socket.emit('getUserRealtimeMessages',
      {
        userId: JSON.parse(localStorage.getItem('user'))._id,
        search: ibSearch.trim(),
        filter: ibFilter
      });
    socket.on('getUserRealtimeMessages', (msg) => {
      console.log("Received message Ib hereeeeeee --------------------- >>>>>>>>>>>>>>>>>>>>:", msg);
      if (msg?.unreadCount) {
        setUnreadAlerts(msg?.unreadCount)
      }

      setLoading(true); // Set loading to false when data fetching is complete

      setIb(msg);
      setLoading(false);
    });

    // return () => socket.removeAllListeners();
    // return () => { socket.close(); };

  }, [socket, ibFilter, ibSearch])

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  if (loading) {
    return <Loaders />; // Render the loader while loading is true
  }


  return (
    <div>
      <div className={`container-fluid pb-5 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
        <div className="container">
          <h2 className="search-note-h2 pt-5 m-auto">Inbox</h2>
          <div className="row position-relative inbox-search-button">
            <div className={`search-bar d-flex mt-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} className="p-3" />
              <input
                type="text"
                placeholder="Search..."
                value={ibSearch}
                onChange={(e) => setIbSearch(e.target.value)}
                className={`theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`} />
            </div>
            <div className={` p-0  search-bar search-bar-right d-flex mt-4 float-end theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
              {/* <FormControl style={{ minWidth: 200 }}>
                <Select value={selectedFilter} onChange={handleFilterChange}>
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <ListItemText primary={option.label} />
                      {selectedFilter === option.value && (
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faCheck} />
                        </ListItemIcon>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <FillterFavorite setIbFilter={(val) => setIbFilter(val)} />
            </div>
          </div>
        </div>

        <div className={` 'back-shadow container m-auto mt-4 viewport-chat theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <div className="px-4 inner-shadow">
            <div className="message-chat pt-4 pb-4">
              {/* user chat */}
              {ib && ib?.messages && ib?.messages.length > 0
                ?
                ib?.messages.map((val, index) => {
                  let userId = JSON.parse(localStorage.getItem('user'))._id || null;
                  let msgId = val?._id || null;
                  let isRejected = val?.isStatus == 2;

                  let senderId = val?.sender?._id || "";
                  let recipientId = val?.recipient?._id || "";

                  let recipientImage = val?.recipient?.profileImage || "";
                  let senderImage = val?.sender?.profileImage || "";

                  let recipientname = val?.recipient?.name || "";
                  let sendername = val?.sender?.name || "";
                  let isRecOnline = val?.recipient?.isOnline || false;
                  let isSendOnline = val?.sender?.isOnline || false;
                  let isOwner = userId && senderId && senderId === userId;

                  let image = isOwner ? recipientImage : senderImage;
                  let name = isOwner ? recipientname : sendername;
                  let isOnline = isOwner ? isRecOnline : isSendOnline;

                  let contractId = val?.contractId || "";
                  let recentMsg = val?.lastMessage?.message || "";

                  let lastMessage =
                    isRejected ?
                      isOwner
                        ? `${val?.recipient?.name} rejected your invitation.`
                        : `You have rejected the invitation request.`
                      : recentMsg;
                  let unreadCount = val?.unreadCount || null;
                  let updatedAt = val?.updatedAt || new Date();

                  let isFav = val?.favoriteBy && val?.favoriteBy.includes(JSON.parse(localStorage.getItem('user'))._id)
                  return (
                    <UserChat key={index}
                      msgId={msgId}
                      image={image}
                      name={name}
                      contractId={contractId}
                      isOnline={isOnline}
                      lastMessage={lastMessage}
                      unreadCount={unreadCount}
                      updatedAt={updatedAt}
                      isFav={isFav}
                    />)
                })
                : <img src={NoMore} alt="" className="no-da mb-4" />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InboxMessage;
