import React, { useEffect, useState } from 'react';
import logo from '../../Images/logo-mini.png';
import Form from 'react-bootstrap/Form';
import { Button, styled } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { addNote, editNote, getDraftNoteDetails } from '../../services/api/notesApi';
import { notifyError, notifySuccess } from '../../toastNotifications';
import { photoUpload } from '../../services';
// import Modal from 'react-bootstrap/Modal';
import xmark from "../../Images/x-mark.png";
import { getWalletDetails } from '../../services/api/walletApi';

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '50rem',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


function Index() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [note, setNote] = useState('');
  const [selectedMediaArr, setSelectedMediaArr] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0); // Progress bar state
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAmountCheckModal, setShowAmountCheckModal] = useState(false);

  const handleOpen = () => setShowAmountCheckModal(true);
  const handleClose = () => setShowAmountCheckModal(false);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleImageClick = (imageUrl) => {
    setSelectedMedia(imageUrl);
    setShowModal(true);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file, file.name);

    try {
      const mediaUpload = await photoUpload(formData, (event) => {
        setUploadProgress(Math.round((100 * event.loaded) / event.total));
      });

      if (mediaUpload.statusCode === 200) {
        setSelectedMediaArr([
          ...selectedMediaArr,
          {
            key: selectedMediaArr.length,
            value: mediaUpload.data.imageUrl,
            type: file.type.split('/')[0]
          }
        ]);
      }
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  const handleAddOrEditNote = async (status) => {

    if ((note && note.trim() !== "") || (selectedMediaArr && selectedMediaArr.length > 0)) {
      let data = id
        ? {
          id,
          note: note.trim(),
          media: selectedMediaArr && selectedMediaArr.length > 0 ? selectedMediaArr.map((val) => val.value) : [],
          status
        }
        : {
          note: note.trim(),
          media: selectedMediaArr && selectedMediaArr.length > 0 ? selectedMediaArr.map((val) => val.value) : [],
          status
        };
      if (status == "0") {
        try {
          const response = id ? await editNote(data) : await addNote(data);
          if (response.statusCode === 200) {
            notifySuccess(`Note added to draft successfully`);
            navigate('/profile');
          }
        } catch (error) {
          console.error("Error in addNote:", error);
        }
      } else if (status == "1") {
        // try {
          // const response = await getWalletDetails();
          // console.log("getWalletDetails response is here -->", response);
          // if (response?.statusCode === 200) {
          //   if (!response?.data?.checkWallet?.ammount || (response?.data?.checkWallet?.ammount && response?.data?.checkWallet?.ammount < 1)) {
          //     handleOpen();
          //   } else {
              try {
                const response = id ? await editNote(data) : await addNote(data);
                if (response.statusCode === 200) {
                  navigate(`/user-list/${response?.data?.createNotes._id || id}`);
                  localStorage.setItem("saved-note", JSON.stringify(data));
                }
              } catch (error) {
                console.error("Error in addNote:", error);
              }
            }
          // }
        // } catch (error) {
        //   console.error("Error in getWalletDetails :", error);
        //   notifyError(error?.response?.data?.message || "Something went wrong");
        // }
      // }
    } else {
      notifyError("Please type your question or upload any related media to proceed further");
    }
  };

  const checkUrlType = async (url) => {
    const extension = url.split('.').pop().toLowerCase();

    if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif' || extension === 'bmp') {
      return 'image';
    } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov' || extension === 'mkv' || extension === 'webm') {
      return 'video';
    } else {
      return 'unknown';
    }
  };

  const handleGetNoteDetails = async () => {
    try {
      const response = await getDraftNoteDetails(id);

      if (response.statusCode === 200) {
        setNote(response?.data?.notesDraftDetails?.note || "");
        let media = response?.data?.notesDraftDetails?.media || [];
        let arr = [];

        for (let i = 0; i < media.length; i++) {
          const element = media[i];
          arr.push({
            key: arr.length,
            value: element,
            type: await checkUrlType(element)
          });
        }

        setSelectedMediaArr(arr);
      }
    } catch (error) {
      console.error("Error in getDraftNoteDetails :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
  };

  const handleSaveLocalNote = async (media) => {
    let mediaArr = [];

    for (let i = 0; i < media.length; i++) {
      const element = media[i];
      mediaArr.push({
        key: mediaArr.length,
        value: element,
        type: await checkUrlType(element)
      });
    }

    setSelectedMediaArr(mediaArr);
  };

  useEffect(() => {
    if (id) {
      handleGetNoteDetails();
    } else {
      if (localStorage.getItem("saved-note")) {
        setNote(JSON.parse(localStorage.getItem("saved-note")).note);
        let localMedia = JSON.parse(localStorage.getItem("saved-note")).media || [];
        handleSaveLocalNote(localMedia);
      }
    }
  }, []);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <>
      <div className={`container-fluid background-main pb-5 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
        <h2 className='search-note-h2 pt-5'>Ask</h2>
        <div className={`search-note m-auto mt-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
          <center>
            <img src={logo} alt="" className='pt-2' />
            <p className={`robert-p pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Hi, {JSON.parse(localStorage.getItem("user")).name} 👋</p>
          </center>
          <center>
            <p className={`robert-p pt-2 pb-2 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>Ask anything, and get an answer from a <br /> real human in this world of AI</p>
          </center>
          <div className="px-4 pt-2">
            <Form.Control
              as="textarea"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Type the question..."
              style={{ height: '100px' }}
              className={`form-input-modal theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}
            />
            <div className='d-flex justify-content-start align-items-center mt-3'>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                className="upload-file-photo m-2"
                style={{ minWidth: '9rem', textAlign: "center" }}
              >
                <FontAwesomeIcon
                  icon={faPhotoFilm}
                  className="upload-file-icon ms-4 ps-3 m-2"
                />
                &nbsp; &nbsp; Add Media
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*,video/*"
                  onChange={handleFileChange}
                />
              </Button>
              <div className="new-scroll-add" >
                <div className={`d-flex mt-0 overflow-reponsive ${selectedMediaArr && selectedMediaArr.length > 3 ? `overflow-x-scroll` : ''}`}>
                  {selectedMediaArr && selectedMediaArr.map((val, index) => (
                    <div className='m-2' style={{ width: '9rem', minWidth: '9rem', height: '8rem', position: 'relative' }} key={index}>
                      {val.type === 'image' ? (
                        <img src={val.value} alt="Preview" className='object-fit-cover' style={{ borderRadius: '10px', width: '100%', height: '100%' }} onClick={() => handleImageClick(val)} />
                      ) : (
                        <video controls className='object-fit-cover' style={{ borderRadius: '10px', width: '100%', height: '100%' }} onClick={() => handleImageClick(val)}>
                          <source src={val.value} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      <DeleteIcon className='absolute ms-5 text-danger' style={{ top: '3%', right: '2%' }}
                        onClick={() => setSelectedMediaArr(selectedMediaArr.filter((obj) => obj.key !== val.key))} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {uploadProgress > 0 && (
              <div className="progress mt-3">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${uploadProgress}%` }}
                  aria-valuenow={uploadProgress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {uploadProgress}%
                </div>
              </div>
            )}

            <div className="mt-4 pb-5 m-auto">
              <button className='save-draft-btn mt-4' onClick={() => handleAddOrEditNote('0')}>Save Draft</button>
              <button className='search-btn mt-4' onClick={() => handleAddOrEditNote('1')}>Search</button>
            </div>
          </div>
        </div>
        {/* <Modal show={showModal} onHide={() => setShowModal(false)} centered >
          <Modal.Body className={`inner-shadow theme-switcher ${theme === 'dark' ? 'dark-theme-only-color' : 'light-theme'}`}>
            <img src={xmark} alt="" className="x-mark  position-absolute right-4 z-index-999" onClick={() => setShowModal(false)} />
            {selectedMedia && selectedMedia.type === 'image' ? (
              <img src={selectedMedia.value} alt="Full View" className='full-width-image-video' />
            ) : selectedMedia && selectedMedia.type === 'video' ? (
              <video controls className='full-width-image-video' >
                <source src={selectedMedia.value} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : null}
          </Modal.Body>
        </Modal> */}


        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          centered >
          <Box sx={styleModal} className={`inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
            <img src={xmark} alt="" className="x-mark  position-absolute right-4 z-index-999" onClick={() => setShowModal(false)} />
            <div className='d-flex justify-content-center'>
            {selectedMedia && selectedMedia.type === 'image' ? (
              <img src={selectedMedia.value} alt="Full View" className='full-width-image-video' />
            ) : selectedMedia && selectedMedia.type === 'video' ? (
              <video controls className='full-width-image-video' >
                <source src={selectedMedia.value} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : null}
            </div>
          </Box>
        </Modal>

      </div>
      <div>
        <Modal
          open={showAmountCheckModal}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={` ' inner-shadow p-0 border-0 border-rduis p-4 theme-switcher ${theme === 'dark' ? 'black-color' : 'light-theme'}`}>
            <div className="d-grid">
              <div className="d-flex justify-content-center">
                <img src={logo} alt="" className="h-75" />
              </div>
              <p className={`text-center py-2 px-4 theme-switcher ${theme === 'dark' ? 'dark-theme-only-white' : 'light-theme'}`}>
                You have insufficient funds for your search. First Add funds to continue.
              </p>
            </div>
            <center className='mt-4'>
              <button className='cancel-btn mx-2' onClick={handleClose}>Cancel</button>
              <button className='logout-btn mx-2' onClick={() => navigate('/profile')}>Add payment</button>
            </center>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default Index;
